import MaterialTable from "@material-table/core";
import { useState, useEffect, createRef,useRef } from "react";
import DetailTable from "./DetailTable";
import { tableIcons } from "./TableProperties";
import { getRowActions } from "./DialogProperties";
import ExpandLessTwoToneIcon from "@material-ui/icons/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import { handleRowClick, expandRow,handleRowCollapse,collapseRow,handleExpandClick,DataNotFoundMessage } from "../../utils/TableUtils";

export const ResultsTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [expandDetail, SetexpandDetail] = useState(false); 
  const [rowId,setRowId]  = useState("");
  const [paging,setPaging]=useState(false);
  const [prevSearch,setPrevSearch]=useState("");
  const [timeoutError,setTimoutError]=useState(false);
  const tblRef = createRef();  
  const expanding=useRef(false);
  useEffect(() => {   
    if (props.dialogStatus) {      
      return;
    }
    if(props.query.expView && !expanding.current)
    {     
      expanding.current=true;
      setTimeout((t=tblRef,f=props.query.searchName) => {expandRow(t, f);}, 100);  
    }    
    else if (props.query.searched && expandDetail && prevSearch===props.query.searchName ) {    
      if (props.query.searchName.length > 0 && !expanding.current) {  
        expanding.current=true;
        expandRow(tblRef, props.query.searchName);
      }
    }
    else{
      collapseRow(tblRef);
      tblRef.current && tblRef.current.onQueryChange();     
      expanding.current=false; 
      setPrevSearch(props.query.searchName);      
    }
    const handleRowExpand = (event) => {
      handleExpandClick(event,tblRef);
    };
    window.addEventListener('mousedown',handleRowExpand);
    return () => {
      window.removeEventListener('mousedown', handleRowExpand);
    };
  }, [props.query.searched, tblRef, expandDetail,rowId,props.dialogStatus,props.query.searchName,prevSearch,props.query.expView]);
  
  const handler = (event) => {        
    handleRowClick(event, tblRef);
  };
 
const handleCollapse=(event)=>{
  handleRowCollapse(event);
}
const GetReq=(props,query) =>{
 return  {
  id: props.query.searchName,
  page: query.page + 1,
  pageSize: query.pageSize,
  isSchool: props.isSchool,
  params: props.query,
};
}
const remoteData =(query) =>  new Promise((resolve, reject) => { 
  collapseRow(tblRef); 
  let req = GetReq(props,query) ;        
  props.GetService(req)
    .then((result) => {
      setIsLoaded(true);
      setPaging(result.size>1) ;
      setTimoutError(result.timeout);
      if(result.detail){            
        SetexpandDetail(true);
        setRowId(result.detail.id);             
      }
      else{
        SetexpandDetail(false);
        setRowId(""); 
      }           
      resolve({
        data: result.values,
        page: result.page - 1,
        totalCount: result.size * result.pageSize,
      });
    });
});

  let detailPanels = props.hideDetail
    ? []
    : [
        {
           tooltip: props.tooltiptext,
           icon: () => <ExpandMoreTwoToneIcon onClick={handler}/>,
           openIcon: () => <ExpandLessTwoToneIcon onClick={handleCollapse} />,
           render: (row) => {
            return (
                <div className="districtDetailTable">
                <DetailTable               
                  rowData={row.rowData}
                  syncKey={props.syncDetailKey}
                  columns={props.detailColumns}                
                  showHeaders={props.showDetailHeaders}
                  DetailService={props.DetailService}
                  handleDialogOpen={props.handleDialogOpen} 
                  dialogStatus={props.dialogStatus}
                  query={{...props.query,expand:expanding.current}}
                  isSchool={props.isSchool}
                />
                </div>
            );
          },
        },
      ];
      let resultTbl =  (
    <MaterialTable
      localization={{
        header : {
          actions: ''
       },       
        body: {
          emptyDataSourceMessage: (
            <DataNotFoundMessage timeoutError={timeoutError}/>
          ),
        },
      }}
    
      tableRef={tblRef}
      isLoading={!isLoaded}
      columns={props.columns}
      icons={tableIcons}
      options={{
        showTitle: false,
        sorting:undefined,
        maxColumnSort:0,
        toolbar: false,
        columnResizable:false,
        draggable:false,
        paging: paging,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],  
        rowStyle: (x) => {        
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f2f2f2" };
          }
        },     
        headerStyle: {
          backgroundColor: "#93c5cf",
          color: "black",
          textAlign: "center",
          fontWeight: 600,
        },
        actionsColumnIndex: -1
      }}
      data={remoteData}
      detailPanel={detailPanels}
      actions={getRowActions({...props})}    
      onRowClick={(event, rowData, togglePanel) => {  if(event.clientX===0 && event.clientY===0){togglePanel()}}}    
    />
  );
  return resultTbl;
};

export default ResultsTable;
