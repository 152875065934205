import {FetchJson,getResults,getStudentResults,getStudentDetailRow,NWEAOrgAPI} from './Helper';
import {CacheService} from "./CacheService";

export const nweaOrgService  = {
    getDistricts: async (req) => {
        const url = req.searchByGuid?
                     `${NWEAOrgAPI}/districts/${req.id.length<=0?"":req.id}?page=${req.page}&pageSize=${req.pageSize}`
                     :`${NWEAOrgAPI}/districts?page=${req.page}&pageSize=${req.pageSize}&name=${req.id}`; ;  
        let key = {id:req.id.length<=0?"default":req.id,Name:"getDistricts",Params:{Page:req.page,PageSize:req.pageSize}};
        return CacheService.GetCacheData(key).then((data)=>{   
          if (data) {        
            return data;
          } else {        
            return FetchJson(url).then((resp) => {         
              CacheService.PutCacheData({...key, Data:resp});
              return resp;
            });}}
            );
      },
      getSchoolByName:async(req)=>{
//Seacrh Schools by Name is disabled for now
            // if(!req.searchByGuid){
            //   return {size:0,values:[]}
            // }
            //:        `${NWEAOrgAPI}/schools/?page=${req.page}&pageSize=${req.pageSize}&Name=${req.id}` ;
      },
      getSchool: async (req) => {
          
        const url = `${NWEAOrgAPI}/schools/${req.id??""}?page=${req.page}&pageSize=${req.pageSize}`;        
        let key = {id:req.id,Name:"getSchool",Params:{Page:req.page,PageSize:req.pageSize}};   
        return CacheService.GetCacheData(key).then((data)=>{   
          if (data) {
            return data;
          } else {        
            return FetchJson(url).then((resp) => {         
              CacheService.PutCacheData({...key, Data:resp});
              return resp;
            });}}
            );
      },
      getSchoolsByDistrict: async (req) => {
        const url = `${NWEAOrgAPI}/districts/${req.id}/schools?page=${req.page}&pageSize=${req.pageSize}`;    
        let key = {id:req.id,Name:"getSchoolsByDistrict",Params:{Page:req.page,PageSize:req.pageSize}};   
        return CacheService.GetCacheData(key).then((data)=>{   
          if (data) {
            return data;
          } else {        
            return FetchJson(url).then((resp) => {
              let data={ ...resp, values: getResults(resp.values, true,req.dtName) };         
              CacheService.PutCacheData({...key, Data:data});
              return data;
            });}}
            );
      },
      getStudentsByOrg: async (req) => {
        let org = req.isSchool ? "schools" : "districts";
        let key = {id:req.params.org.id,Name:"StudentsByOrg",Params:{Page:req.page,PageSize:req.pageSize}};  
        let url = `${NWEAOrgAPI}/${org}/${req.params.org.id}/students?page=${req.page}&pageSize=${req.pageSize}`;
        return CacheService.GetCacheData(key).then((data)=>{   
        if (data) {
          return data;
        } else {
          return FetchJson(url).then((resp) => {
            let data = { ...resp, values: getStudentResults(resp.values, true) };
            CacheService.PutCacheData({...key, Data:data});
            return data;
          });}}
          );
        }
      ,  
      

  getStudentsByOrgByStudentId: async (req) => {
    let org=req.isSchool?"schools":"districts";
    let key = {id:req.params.searchName+'$'+req.params.org.id,Name:`StudentsBy${org}ByStudentId`,Params:{Page:req.page,PageSize:req.pageSize}};  
    let url = `${NWEAOrgAPI}/${org}/${req.params.org.id}/students/${req.params.searchName}?page=1&pageSize=1`;
    
    return CacheService.GetCacheData(key).then((data)=>{   
      if (data) {
        return data;
      } else {       
        return FetchJson(url).then((resp) => {
          if(resp && resp.values && resp.values.length>0){
          let data = { ...resp, values: getStudentResults(resp.values, true),detail:getStudentDetailRow(resp.values) };
          CacheService.PutCacheData({...key, Data:data});
          return data;}
          else{
            let nurl = `${NWEAOrgAPI}/${org}/${req.params.org.id}/students?name=${req.params.searchName}&page=${req.page}&pageSize=${req.pageSize}`;
            return FetchJson(nurl).then((resp) => {
              let data1 = { ...resp, values: getStudentResults(resp.values, true),detail:getStudentDetailRow(resp.values) };
              CacheService.PutCacheData({...key, Data:data1});
              return data1;
            });
          }
        });}}
        );
   
  },
  searchDistrictsAndSchools: async (req) => { 
        return nweaOrgService.getDistricts(req).then((result) => {
          if (result.size === 0 && req.searchByGuid) {
            return nweaOrgService.getSchool(req).then((sch) => {
              if (sch.size > 0) {
                return nweaOrgService.getDistricts({id: sch.values[0].districtBid,page: 1,pageSize: req.pageSize,searchByGuid:true})
                  .then((resp) => {
                    return { ...resp, values: getResults(resp.values), detail: {id:sch.values[0].schoolBid,name:sch.values[0].name}};
                  });
              }
              else{
                return { ...result, values: [] } 
              }
            });
          } else {        
            return { ...result, values: getResults(result.values) };
          }
        });
      }
}