
import {setRenStudentResults,AISAPI,AISAPIV1,  
  FetchJsonPostgres,setRPIDResults, getRenStudentDetailRow,PostJsonPostgres} from './Helper';
  import { v4 as uuidv4 } from 'uuid';

export const renService = { 
   
  searchDistrictsAndSchools: async (req) => {    
    let url=`${AISAPI}/${req.id.length<=0?"":req.id}?assessmentProviderType=Renaissance&page=${req.page}&pageSize=${req.id.length<=0?req.pageSize:1}`; 
    return FetchJsonPostgres(url).then((resp) => {
      return { ...resp, values: setRPIDResults(resp.values) };
    });
  }, 
  summaryStudentAssessments: async (req) => {
    let urlpart = "/";    
    if (req.params.searchName.length > 0 ) {
      urlpart = `/students/${req.params.searchName}/`;
      if(req.page===req.currentPage){
         req.page = 1;
      }      
    }
    let url = `${AISAPI}/${req.params.org.id}${urlpart}assessments?page=${req.page}&pageSize=${req.pageSize}&teststartdate=${req.params.StartDate}&testenddate=${req.params.EndDate}`;
    return FetchJsonPostgres(url, "R").then((resp) => {
      return {
        ...resp, 
        values: setRenStudentResults(resp.values),
        detail: getRenStudentDetailRow(resp.values),
      };
    });
  }, 
  
  getStudentAssessments: async (req) => {
    let searchName=req.params.searchName.length>0?req.params.searchName:req.id; 
    let url=`${AISAPI}/${req.params.org.id}/students/${searchName}/assessments?page=${req.page}&pageSize=${req.pageSize}&teststartdate=${req.params.StartDate}&testenddate=${req.params.EndDate}`;   
    return FetchJsonPostgres(url,'R').then((resp) => {
      return { ...resp, values: setRenStudentResults(resp.values,1) };
    });
  },  
    
  syncStudentAssessments: async (req) => {
    
    var reqBody=  {
      AssessmentProviderType:"Renaissance",
      EntityType:"Assessment",
      Payload: {
        StartDate:req.params.startDate,
        EndDate:null,
        ExternalId:req.params.id
      },
      Source:"ais-ui-manualsync-renaissance",
      OwnerId:req.payload.name,
      Id:uuidv4()
    };    
    const url = `${AISAPIV1}/event`;
    return PostJsonPostgres(url, reqBody);
  }
};

