//Original App.js with okta

import React from 'react';
import './styles/App.css'
import Header from './Components/Common/Header';
import Home from './Home';
import oktaConfig from "./config/oktaConfig";
import { Route, useHistory } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Login from './Components/Okta/Login';
import StudentPage from './Components/Pages/StudentPage'
import RenStudentPage from './Components/Pages/RenStudentPage'
function App() {
  const oktaAuth = new OktaAuth(oktaConfig.oidc);
  const history = useHistory();
  const customAuthHandler = () => {
    history.push('/');
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <div className="App">
      <div className="page">
        <div className="content">
          <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Header />
            <Route path="/" exact={true} component={Home} />
            <Route path='/login' exact={true} component={Login} />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute path="/student:bid" component={StudentPage} />
            <SecureRoute path="/RenStudent:bid" component={RenStudentPage} />
          </Security>
        </div>
      </div>
    </div>
  );
}

export default App;