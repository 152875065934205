import ClickableCell  from './ClickableCell';
import MultiLineDataCell  from './MultiLineDataCell';
export const RenDistrictPageColumns =  [   
  {
    title: "ORGANIZATION RPID",
    field: "rpid", 
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:true,channel:"REN"}} className={"tableLink"}/>,     
  },
  {
    title: "Date Added",
    field: "createdon",
    align:'left',      
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:false,channel:"REN"}} className={"tableLink"}/>,  
  }, 
];
export const RenStudentPageColumns =  [   
  {
    title: "STUDENT ID",
    field: "rid", 
    align:'left',   
  },
  {
    title: "TEST DATE",
    field: "testdate",
    align:'center',      
  }, 
  {
    title: "IMPORT DATE",
    field: "importdate",
    align:'center',       
  }, 
  {
    title: "TEST",
    field: "test",
    align:'center',       
  }, 
  {
    title: "SCORE",
    field: "score",
    align:'center',      
  }
];

export const RenStudentDetailPageColumns =  [   
  {
    title: "PlaceHolder",
    field: "PlaceHolderId", 
    align:'left',   
  },
  {
    title: "TEST DATE",
    field: "testdate",
    align:'center',      
  }, 
  {
    title: "IMPORT DATE",
    field: "importdate",
    align:'center',        
  }, 
  {
    title: "TEST",
    field: "test",
    align:'center',      
  }, 
  {
    title: "SCORE",
    field: "score",
    align:'center',       
  }, 
   
];
export const NweaDistrictPageColumns =  [   
    {
      title: "DISTRICT/SCHOOL",
      field: "name", 
      align:'left',
      render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:true,channel:"NWEA"}} className={"tableLink"}/>,     
    },
    {
      title: "BID",
      field: "rid",
      align:'left',      
      render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:false,channel:"NWEA"}} className={"tableLink"}/>,  
    }, 
 ];  
 export const NweaDistrictDetailsColumns =  [ 
  {
    title: "DISTRICT/SCHOOL",
    field: "name", 
    align:'left' ,
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:true,isName:true,channel:"NWEA"}} className={"tableLink"}/>,   
  },    
  {
    title: "BID",
    field: "rid",
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:true,isName:false,channel:"NWEA"}} className={"tableLink"}/>,
  }, 
]; 
export const NweaStudentPageColumns=[
  {
    title: "SCHOOL",
    field: "schoolname", 
    align:'left'  
  
  },
  {
    title: "STUDENT",
    field: "name",
    align:'center'        
  },   
    {
      title: "NWEA ID (APP)",
      field: "rid", 
      align:'center',
    
    },
    {
      title: "STUDENT BID",
      field: "studentBid",
      align:'left',        
    },
    
  ];
export const NweaStudentDetailsColumns=[
      {
        title: "SCHOOL BID",
        field: "schoolBid",
        align:'center',        
        cellStyle:{verticalAlign:'baseline'}      
      },
      {
        title: "TEST DATE",
        field: "testDate",
        align:'center',        
        cellStyle:{verticalAlign:'baseline'}        
      },
      {
        title: "TEST TAKEN",
        field: "testName",
        align:'center',  
        render: (rowData) =><MultiLineDataCell cellData={rowData.testName} additionalInfo={rowData.IANames}/>     
      },
      {
        title: "RIT SCORE",
        field: "rit",
        align:'center',        
        render: (rowData) =><MultiLineDataCell cellData={rowData.rit} additionalInfo={rowData.IAScores} noStyle={true}/>    
      },
      {
        title: "IMPORT DATE",
        field: "importDate",
        align:'center',               
        cellStyle:{verticalAlign:'baseline'}              
      },
]
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  export default  {
      NweaDistrictPageColumns,
      NweaDistrictDetailsColumns,
      NweaStudentPageColumns,
      NweaStudentDetailsColumns, 
      RenDistrictPageColumns,
      RenStudentDetailPageColumns,
      RenStudentPageColumns
    };