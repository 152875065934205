import { useHistory } from "react-router-dom";
export default function ClickableCell(props){
  let history = useHistory(); 
  let {district,districtBid,name,rid,isDetail,rpid,channel,createdon,entityType,isName}=props.data;
  let data={district,districtBid,name,rid,isDetail,rpid,channel,createdon,entityType,isName};
  let redirect={pathname:"/student:"+rid,state:{data:{...data,id:rid}}};     
  var label=isName?name:rid;
  
  if (channel ===  "REN"){
      redirect={pathname:"/RenStudent:"+rpid,state:{data:{...data,id:rpid}}};
      label=isName?rpid:createdon;
    }
 const handleRowClick=(event)=>{ history.push(redirect); }
return (<span  onClick={handleRowClick}>{label}</span>)
}
