import config from "../config/baseconfig";
import {formatTime} from "../utils/dateutils";

export const NWEAOrgAPI = `${config.NWEAAPI}v2.0/organizations`;
export const NWEADashboardAPI = `${config.NWEAAPI}v1.0/dashboard`;
export const NWEAResultAPI = `${config.NWEAAPI}v2.0/assessments/results`;
export const NWEASyncAPI =`${config.NWEAAPI}tasks/sync`;

export const RENAISSANCEOrgAPI =`${config.RENAISSANCEAPI}districts`;
export const AISAPI =`${config.AssessmentAPI}/organizations`;
export const AISAPIV1 = `${config.AssessmentAPIV1}`;
export const AISAPIV4 = `${config.AssessmentAPIV4}`;
export const getResults = (values, sch = false, dist = "") => {
  let modvalues = [];
  for (var i = 0; i < values.length; i++) {
    let obj = values[i];
    modvalues.push({
      rid: sch ? obj.schoolBid : obj.districtBid,
      id: i,
      name: obj.name,
      district: dist,
      districtBid: obj.districtBid,
    });
  }
  return modvalues;
};
 export const getStudentResults = (values) => {
   let modvalues = [];
   for (var i = 0; i < values.length; i++) {
     let obj = values[i];
     modvalues.push({
       studentBid: obj.studentBid,
       rid: obj.studentId,
       id: i,
       schoolname: obj.schoolBid,
       name: obj.lastName + "," + obj.firstName,
     });
   }
   return modvalues;
 };
  export const getStudentDetailRow=(values)=>{
      if(values.length===1){
        return {id:values[0].studentId}
      }
      return null;
  }
  const GetInstructionalAreas=function (arrIA) {
    let names = [];
    let scores = [];
    for (var i = 0; i < arrIA.length; i++) {
      names.push(arrIA[i].instructionalAreaName);
      scores.push(arrIA[i].score);
    }
    return { IANames: names, IAScores: scores };
  }
  export const getAssessmentResults = (values) => {
    let modvalues = [];
    for (var i = 0; i < values.length; i++) {
      let obj = values[i];
      let objIA = GetInstructionalAreas(obj.instructionalAreas);
      modvalues.push({
        id:i,
        schoolBid:obj.schoolBid,
        testName:obj.testName,        
        rit:obj.rit,
        importDate: formatTime(obj.importDate,"MM/DD/YYYY"),
        testDate: formatTime(obj.testDate,"MM/DD/YYYY"),
        IANames:objIA.IANames,
        IAScores:objIA.IAScores     
      });
    }
    return modvalues;
  };
  export const setRPIDResults = (values) => {
    let modvalues = [];
    for (var i = 0; i < values.length; i++) {
      let obj = values[i];
      modvalues.push({
        rpid: obj.externalId,
        id:i,
        entityType:obj.entityType,
        createdon: formatTime(obj.modifiedOn,"MM/DD/YYYY HH:MM"),
      });
    }
    return modvalues;
  };
  export const setRPIDResultsV4 = (values) => {
    let modvalues = [];
    for (var i = 0; i < values.length; i++) {
      let obj = values[i];
      modvalues.push({
        rpid: obj.districtId,
        id:i,
        entityType:obj.vendorType,
        createdon: formatTime(obj.lastAssessmentSync,"MM/DD/YYYY HH:MM"),
      });
    }
    return modvalues;
  };
  export const setRenStudentResults = (values,startIndex=0) => {
    let modvalues = [];  
    for (var i = startIndex; i < values.length; i++) {
      let obj = values[i];     
      modvalues.push({
        id:i,
        rid: obj.studentId, 
        testdate:formatTime(obj.testDate,"MM/DD/YYYY HH:MM"), 
        importdate:formatTime(obj.modifiedOn,"MM/DD/YYYY HH:MM"),
        test:obj.testName, 
        score:obj.score, 
        uscore:obj.score,
        HasMoreResults: obj.hasMoreRecords   
      });
    }
    return modvalues;
  };
  export const setRenStudentResultsv4 = (values,startIndex=0) => {
    let modvalues = [];  
    for (var i = startIndex; i < values.length; i++) {
      let obj = values[i];     
      modvalues.push({
        id:i,
        rid: obj.studentId, 
        testdate:formatTime(obj.testDate,"MM/DD/YYYY HH:MM"), 
        importdate:formatTime(obj.modifiedOn,"MM/DD/YYYY HH:MM"),
        test:obj.name, 
        score:obj.score, 
        uscore:obj.score,
        HasMoreResults: obj.hasMoreRecords   
      });
    }
    return modvalues;
  };
export const getRenStudentDetailRow=(values)=>{
  if(values.length>0 && values[0].hasMoreRecords){    
   return {id:values[0].studentId}
  }
  return null;
}
  export const FetchJson = async (url,app='N') => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 8000);  
    const token = app==='N'?config.NWEAToken:config.RENAISSANCEAPIToken;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      signal:controller.signal
    })
      .then((res) => res.json())
      .then((res) => {        
        clearTimeout(id);
        if (!res.values) {          
          return { size: 0, page: 1, values: [] ,timeout:false};
        }
        return res;
      },
      (error) =>{
        console.log("API call failed :"+error);
        return { size: 0, page: 1, values: [],timeout:true };
      }
      );
      
  }; 
  export const FetchJsonDynamo = async (url) => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 10000);  
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}`,Application:`${app}` },
      signal:controller.signal
    })
      .then((res) =>{     
       if(res.status===404){
        return JSON.stringify({ size: 0, paginationtoken: undefined, values: [] ,timeout:true});
       }
       return res.json();
      }
       )
      .then((res) => {        
        let paginationtoken =undefined;      
        clearTimeout(id);
        if (!res.results) {          
          return { size: 0, paginationtoken: paginationtoken, values: [] ,timeout:false};
        }       
        if(!res.pagination.paginationToken)
         {
           paginationtoken=res.pagination.paginationToken;
         }
        return { size:res.results.length,values:res.results,paginationtoken:paginationtoken};
      },
      (error) =>{
        console.log("API call failed :"+error);
        return { size: 0, paginationtoken: undefined, values: [] ,timeout:true};
      }
      );
      
  }; 
  export const FetchJsonPostgres = async (url) => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 8000);  
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}`,Application:`${app}` },
      signal:controller.signal
    })
      .then((res) => res.json())
      .then((res) => {
        clearTimeout(id);
        if (!res.values) {          
          return { size: 0, page: 1, values: [] ,timeout:false};
        }
        return res;
      },
      (error) =>{
        console.log("API call failed :"+error);
        return { size: 0, page: 1, values: [] ,timeout:true};
      }
      );
      
  }; 
  export const PostJson = async (url,req,app='N') => {
    const token = app==='N'?config.NWEAToken:config.RENAISSANCEAPIToken;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify(req.params)
    }).then((res) => res.json())
    .catch(error => console.log(error) );
  };
  export const PostJsonPostgres = async (url,req) => {
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Application:`${app}`
      },
      body:JSON.stringify(req)
    }).then((res) => res.json())
    .catch(error => console.log(error) );
  };
   /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  export default {
    FetchJson,
    getResults,
    getStudentResults,
    PostJson,
    setRenStudentResults,
    setRPIDResults,    
    getRenStudentDetailRow,
    PostJsonPostgres,
    NWEAOrgAPI,
    NWEADashboardAPI,
    NWEAResultAPI,
    NWEASyncAPI,
    RENAISSANCEOrgAPI
  }; 

