import MaterialTable from "@material-table/core";
import { useState, useEffect, createRef,useRef } from "react";
import DetailTable from "./DetailTable";
import { tableIcons } from "./TableProperties";
import { getRowActions } from "./DialogProperties";
import ExpandLessTwoToneIcon from "@material-ui/icons/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import { handleRowClick, expandRow,handleRowCollapse,collapseRow,handleExpandClick,DataNotFoundMessage } from "../../utils/TableUtils";

export const RenResultsTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [expandDetail, SetexpandDetail] = useState(false); 
  const [rowId,setRowId]  = useState("");
  const [paging,setPaging]=useState(false);
  const [prevSearch,setPrevSearch]=useState("");
  const [currentPage,setCurrentPage]=useState(0);
  const [currentPageSize,setcurrentPageSize]=useState(5);
  const [timeoutError,setTimoutError]=useState(false);

  const tblRef = createRef();  
  const loading=useRef(true);
  const NeedsToLoad = (query) => {
    let searchd=props.query.searched && props.query.searchName.length>0; 
    let reload=props.query.reload;
    let timeFrameChanged=props.query.timeFrameChanged;
    let pagingLocal=currentPage < query.page + 1 ||
    currentPageSize < query.pageSize ||
    currentPageSize > query.pageSize ||
    currentPage > query.page + 1;

    return  loading.current && (searchd ||reload||pagingLocal||timeFrameChanged);
  };

  const GetReq=(props,query) =>{
    return  {
     id: props.query.searched?props.query.searchName:'',
     page: query.page + 1,
     pageSize: query.pageSize,
     isSchool: props.isSchool,
     params: props.query,
     currentPage:currentPage
   };
   }
  const remoteData =(query) =>
  new Promise((resolve, reject) => { 
    collapseRow(tblRef);
    if (NeedsToLoad(query)) {
      loading.current=false;     
      let req = GetReq(props,query);
      props.GetService(req).then((result) => {        
        setIsLoaded(true);
        setPaging(result.size > 1);
        setCurrentPage(result.page);
        setcurrentPageSize(result.pageSize);
        setTimoutError(result.timeout);
        if(result.detail && result.values.length===1){
          SetexpandDetail(true);
          setRowId(result.detail.id);
        }
        else{
          SetexpandDetail(false);
          setRowId("");
        }
        loading.current=true;
        resolve({
          data: result.values,
          page: result.page - 1,
          totalCount: result.size * result.pageSize,
        })      
      })
    }
  }

  )
  
  useEffect(() => {
    if (props.dialogStatus) {
      return;
    }
    if (props.query.searched && expandDetail  && props.query.searchName.length > 0) {      
        expandRow(tblRef, rowId);      
        return;     
    } else {  
      if(!props.query.timeFrameChanged && !props.query.searched && prevSearch===props.query.searchName){
        return;
      }  
      tblRef.current && tblRef.current.onQueryChange();
      setPrevSearch(props.query.searchName);
      collapseRow(tblRef);
    }
    const handleRowExpand = (event) => {
      handleExpandClick(event, tblRef);
    };
    window.addEventListener("mousedown", handleRowExpand);
    return () => {
      window.removeEventListener("mousedown", handleRowExpand);
    };
  }, [props.query, tblRef, expandDetail,rowId,props.query.searchName,prevSearch,props.query.timeFrameChanged,props.dialogStatus]);
  
  const handler = (event) => {
    handleRowClick(event, tblRef);
  };
  const handleCollapse = (event) => {
    handleRowCollapse(event);
  };
const detailPanels = props.hideDetail
  ? []
  : [
      (row) => ({
        disabled: !row.HasMoreResults,
        tooltip: row.HasMoreResults ? props.tooltiptext : null,
        icon: () => row.HasMoreResults ? <ExpandMoreTwoToneIcon onClick={handler} />:<></>,
        openIcon: () =>row.HasMoreResults ?<ExpandLessTwoToneIcon onClick={handleCollapse} />:<></>,
        render: (row) => {
          return (row.rowData.HasMoreResults?
            <div>
              <div className="districtDetailTable">
                <DetailTable
                  rowData={row.rowData}
                  syncKey={props.syncDetailKey}
                  columns={props.detailColumns}
                  showHeaders={props.showDetailHeaders}
                  DetailService={props.DetailService}
                  handleDialogOpen={props.handleDialogOpen}
                  dialogStatus={props.dialogStatus}
                  query={props.query}
                  isSchool={props.isSchool}
                />
              </div>
            </div>:<div></div>
          );
        },
      }),
    ];
      let resultTbl =  (
    <MaterialTable
      localization={{
        header : {
          actions: ''
       },
        body: {
          emptyDataSourceMessage: (
            <DataNotFoundMessage timeoutError={timeoutError}/>
          ),
        },
      }}
      tableRef={tblRef}
      isLoading={!isLoaded}
      columns={props.columns}
      icons={tableIcons}
      options={{
        showTitle: false,
        sorting:undefined,
        maxColumnSort:0,
        toolbar: false,
        columnResizable:false,
        draggable:false,
        paging: paging,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],  
        rowStyle: (x) => {        
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f2f2f2" };
          }
        },     
        headerStyle: {
          backgroundColor: "#93c5cf",
          color: "black",
          textAlign: "center",
          fontWeight: 600,
        },
        actionsColumnIndex: -1
      }}
      data={remoteData}
      detailPanel={detailPanels}
      actions={getRowActions({...props})}    
      onRowClick={(event, rowData, togglePanel) => {  if(event.clientX===0 && event.clientY===0){togglePanel()}}}        
    />
  );
  return resultTbl;
};

export default RenResultsTable;