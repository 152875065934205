import { useContext,useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CustomDialogContent from "./CustomDialogContent";
import DialogTitleWithClose from "./DialogTitleWithClose";
import {DataContext} from "../DataContext";
import { syncServiceReducer } from "../ServiceReducer";
import {formatTime,AddnDays} from '../../../utils/dateutils';

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center',    
  },
}))(MuiDialogActions);
export function ConfirmDialog(props) {  
   const {handleClose,action,id,open } = props; 
   const [confText,setConfirmation] =useState("")
   const context=useContext(DataContext);
   const loginDataMissing= !(context && context.userInfo) ;   
   const defaultdate=formatTime(AddnDays(-30),"YYYY-MM-DD");
   const [startDate,setstartDate]=useState(formatTime(AddnDays(-30),"YYYY-MM-DD"));
   const [disableOkBtn, setDisableOkBtn] = useState(false) 
  const syncFn = () => {
    setConfirmation("");
    setDisableOkBtn(true);
    if (context && context.userInfo) {
      let confirmationMsg=context.getConfirmationMsg(action);
      syncServiceReducer({
        type: action,
        payload: context.userInfo,
        params: { startDate: startDate, id: id },
      }).then(() => {
        setConfirmation(confirmationMsg);
      });
    }
  };
  const handleDateChange=(event)=>{
    setConfirmation("");
    setDisableOkBtn(false) ;
    setstartDate(event.target.value);    
  }
  const handleCloseClick=(e)=>{   
    handleClose(e);  
    setConfirmation("");
    setDisableOkBtn(false) ;   
  }
  return (  
    <Dialog
      keepMounted={true}
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"     
    >
      <DialogTitleWithClose {...props} handleClose={handleCloseClick}></DialogTitleWithClose>
      <CustomDialogContent {...props} defaultdate={defaultdate} handleDateChange={handleDateChange}/>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseClick} color="primary">
          Cancel
        </Button>
        <Button variant="outlined" onClick={syncFn} color="primary" autoFocus disabled={loginDataMissing || disableOkBtn}>
          OK
        </Button>
      </DialogActions>
      <div className="dialogConfirmText">
        {confText}
      </div>
    </Dialog>   
  );
}
